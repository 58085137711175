import React, { Fragment } from 'react';
import styled from 'styled-components';
import StaticTemplate from 'components/Templates/Static';

const SectionListData = [
  {
    num: '01',
    title: '個人情報の取得',
    text: <p>当社は、業務上必要な範囲かつ、適法で公正な手段により個人情報を取得します。</p>,
  },
  {
    num: '02',
    title: '個人情報の利用目的',
    text: (
      <Fragment>
        <p>当社は、取得した個人情報を、当該業務の遂行に必要な範囲内でのみ利用します。それら以外の他の目的に利用することはありません。 安全管理に関する取扱規程等の整備及び実施体制の整備等、十分なセキュリティ対策を講じるととともに、 利用目的の達成に必要とされる正確性・最新性を確保するために適切な措置を講じています。</p>
        <ul>
          <li>当社、もしくは当社運営サイトからの問い合わせにメールで返信する場合。</li>
          <li>お客様に情報を提供する場合。</li>
          <li>その他、必要に応じてお客様に連絡する場合。</li>
        </ul>
        <p>上記の利用目的を変更する場合には、その内容をホームページ等により公表します。</p>
      </Fragment>
    ),
  },
  {
    num: '03',
    title: '個人データの安全管理措置',
    text: <p>当社は、取り扱う個人データの漏洩、滅失またはき損の防止その他の個人データの安全管理のため、 安全管理に関する取扱規程等の整備及び実施体制の整備等、十分なセキュリティ対策を講じるととともに、 利用目的の達成に必要とされる正確性・最新性を確保するために適切な措置を講じています。</p>,
  },
  {
    num: '04',
    title: '個人データの第三者への提供',
    text: (
      <Fragment>
        <p>当社は、法令に定める場合を除き、個人情報を、 事前に本人の同意を得ることなく、第三者には提供いたしません。 安全管理に関する取扱規程等の整備及び実施体制の整備等、十分なセキュリティ対策を講じるととともに、 利用目的の達成に必要とされる正確性・最新性を確保するために適切な措置を講じています。</p>
        <ul>
          <li>法令に基づく場合。</li>
          <li>国の機関、もしくは地方公共団体、警察や司法機関・税務署などの要請があった場合。</li>
          <li>人の生命、身体または財産の保護のために必要がある場合、ご本人の同意を得ることが困難であるとき。</li>
          <li>お客様に商品やサービスを提供する目的で当社からの委託を受けて業務を行う提携会社が情報を必要とする場合。</li>
        </ul>
      </Fragment>
    ),
  },
  {
    num: '05',
    title: 'アクセス解析について',
    text: (
      <Fragment>
        <p>
          当社では、お客様のサイト利用状況を把握するためにGoogle Inc.が提供しているGoogle Analyticsを使用しています。Google Analyticsの利用により収集されたデータは、 Google Inc.のプライバシーポリシーに基づいて管理されます。詳しくは<a href="https://policies.google.com/privacy?hl=ja&gl=jp" target="_blank" rel="noopener noreferrer">Google Inc.のプライバシーポリシー</a>またはGoogle Inc.が随時提供するその他のURLをご確認ください。 安全管理に関する取扱規程等の整備及び実施体制の整備等、十分なセキュリティ対策を講じるととともに、 利用目的の達成に必要とされる正確性・最新性を確保するために適切な措置を講じています。
          <br />
          <br />
          当社では、ディスプレイ広告に対応するGoogle Analyticsの「ユーザー属性とインタレストカテゴリに関するレポート」機能を導入しており、 インタレストベース広告のデータや第三者のユーザーデータ（年齢、性別、興味や関心など）を収集し、サイト改善や適切な広告表示に利用しています。
        </p>
        <ul>
          <li>Google アナリティクス リマーケティング</li>
          <li>Google ディスプレイ ネットワークの表示回数レポート</li>
          <li>Google アナリティクスのユーザーの分布とインタレスト カテゴリに関するレポート</li>
          <li>Google アナリティクスを使用して広告Cookieと匿名IDを使ったデータを収集する統合的なサービス</li>
        </ul>
        <p>Google Analyticsのサービス利用による損害については、当社は責任を負わないものとします。Google Analyticsの広告向けの機能をオプトアウト（無効化）される場合は、ご利用のブラウザの設定によりCookieの機能を無効にするか、 <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank" rel="noopener noreferrer">Googleアナリティクス オプトアウト アドオン</a>をご利用ください。</p>
      </Fragment>
    ),
  },
  {
    num: '06',
    title: '個人情報の開示について',
    text: <p>当社は、ユーザー本人からの個人情報開示、変更、利用停止、削除の要求が行われた場合、各サービスの利用規約において定める開示方法に基づき受け入れるものとします。</p>,
  },
];

const HeadText = styled.div`
  position: relative;
  margin-bottom: 54px;
  line-height: 1.75;
  z-index: 1;
  ${props => props.theme.media.tab} {
    margin-bottom: 48px;
    font-size: 1.4rem;
  }
`;

const SectionWrap = styled.section`
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
  ${props => props.theme.media.tab} {
    margin-bottom: 40px;
  }
  .title {
    display: flex;
    align-items: center;
    .num {
      width: 75px;
      padding-bottom: 6px;
      border-bottom: 1px solid #000;
      font-size: 4rem;
      letter-spacing: 0.1em;
      font-family: 'Marcellus', serif;
      ${props => props.theme.media.tab} {
        width: 55px;
        font-size: 3rem;
      }
    }
    h3 {
      padding-left: 15px;
      font-size: 2rem;
      ${props => props.theme.media.tab} {
        font-size: 1.6rem;
      }
    }
  }
  .text {
    margin: 40px auto 0 35px;
    ${props => props.theme.media.tab} {
      margin: 20px auto 0;
    }
    p {
      line-height: 1.75;
      ${props => props.theme.media.tab} {
        font-size: 1.4rem;
      }
      a {
        text-decoration: underline;
      }
    }
    ul {
      margin: 10px 0;
      li {
        position: relative;
        margin-left: 25px;
        line-height: 1.75;
        ${props => props.theme.media.tab} {
          margin-left: 20px;
          font-size: 1.4rem;
        }
        &:before {
          position: absolute;
          content: '●';
          font-size: 10px;
          top: 0.5em;
          left: -15px;
          font-family: Arial;
        }
      }
    }
  }
`;

const Privacy: React.FC = () => {
  return (
    <StaticTemplate
      title="Privacy Policy"
      titleSub="プライバシーポリシー"
    >
      <HeadText>
        コクラボ株式会社（以下、「当社」といいます）は、個人情報保護の重要性を認識し、以下の方針に基づき個人情報の保護に努めてまいります。
      </HeadText>
      {SectionListData.map((item, index) => (
        <SectionWrap key={item.num}>
          <div className="title">
            <p className="num">{item.num}</p>
            <h3>{item.title}</h3>
          </div>
          <div className="text">
            {item.text}
          </div>
        </SectionWrap>
      ))}
    </StaticTemplate >
  );
};
export default Privacy;
