import React, { Fragment } from 'react';
import Head from 'components/LV1/Seo';
import Privacy from 'components/LV3/Privacy';

const PrivacyPage: React.FC = () => (
  <Fragment>
    <Head
      title="PRIVACY POLICY"
      description="コクラボ株式会社のプライバシーポリシーページ。個人情報保護に関する基本方針などをご案内しています。"
    />
    <Privacy />
  </Fragment>
);
export default PrivacyPage;